import {
  BAPIInvoicingPayee,
  BAPIInvoicingCompany,
  InvoicingPayee,
  InvoicingCompany,
} from "@/bapi-client/types/external-invoicing";

export function invoicingPayeeFactory(data: BAPIInvoicingPayee): InvoicingPayee {
  const { addresses, ...theRest } = data;
  const address = data.addresses[0];
  return {
    ...theRest,
    address: {
      id: address?.id ?? "",
      address_1: address?.address_1 ?? "",
      address_2: address?.address_2 ?? "",
      city: address?.city ?? "",
      state: address?.state ?? "",
      country: address?.country ?? "",
      postal_code: address?.postal_code ?? "",
    },
  };
}

export function invoicingCompanyFactory(data: BAPIInvoicingCompany): InvoicingCompany {
  const { addresses, ...theRest } = data;
  const address = data.addresses[0];
  return {
    ...theRest,
    address: {
      id: address?.id ?? "",
      address_1: address?.address_1 ?? "",
      address_2: address?.address_2 ?? "",
      city: address?.city ?? "",
      state: address?.state ?? "",
      country: address?.country ?? "",
      postal_code: address?.postal_code ?? "",
    },
  };
}
