import { fetchHandler } from "../fetch";
import { BAPI_COMMANDS } from "../types/commands";
import type { InteledeskParams } from "../types";

export * as templateHandlers from "./templateHandler";

export async function uploadS3File(file: File, upload_url: string) {
  return await fetchHandler(BAPI_COMMANDS.S3_UPLOAD_FILE, "PUT", upload_url, {
    body: file,
    headers: { "Content-Type": file.type },
  });
}

export async function submitInteledeskForm(data: InteledeskParams) {
  let url = "/inteledesk";
  if (data.category === "BUG") {
    url += "/bug";
  }
  if (data.category === "FEATURE") {
    url += "/feature";
  }
  if (data.category === "MANUAL_ADJUSTMENT") {
    url += "/manual_adjustment";
  }
  const response = await fetchHandler(BAPI_COMMANDS.INTELEDESK_SUBMIT, "POST", url, {
    body: data,
  });

  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    githubURL: parsed.data.github_url,
  };
}
