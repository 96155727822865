import type { RouteRecordRaw } from "vue-router";

const InvoicingPage = () => import("../pages/invoicing/InvoicingPage.vue");
const InvoicesPage = () => import("../pages/invoicing/InvoicesPage.vue");
const PayeeProfilePage = () => import("../pages/invoicing/PayeeProfilePage.vue");
const NewInvoicePage = () => import("../pages/invoicing/NewInvoicePage.vue");
const EditInvoicePage = () => import("../pages/invoicing/EditInvoicePage.vue");

export const invoicingRoutes: RouteRecordRaw = {
  name: "invoicingPage",
  component: InvoicingPage,
  path: "/invoicing/:customerId",
  meta: {
    authenticated: true,
    flag: "hasInvoicingModule",
    // permissions: ["invoicing_invoice_dashboard", "invoicing_settings"],
  },
  props: true,
  children: [
    {
      name: "invoicesPage",
      path: "invoices",
      component: InvoicesPage,
      props: true,
      meta: {
        // permission: "invoicing_invoice_dashboard",
        title: "Telegraph - Invoicing Dashboard",
      },
    },
    {
      name: "newInvoicePage",
      path: "invoices/new",
      component: NewInvoicePage,
      props: true,
      meta: {
        title: "Telegraph - New Invoice",
      },
    },
    {
      name: "editInvoicePage",
      path: "invoices/:invoiceId/edit",
      component: EditInvoicePage,
      meta: {
        title: "Telegraph - Edit Invoice",
      },
    },
    {
      name: "payeeProfilePage",
      path: "profile",
      component: PayeeProfilePage,
      props: true,
    },
  ],
};
