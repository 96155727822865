import { rootClasses } from "./formkit-theme-telegraph/formkit.theme";
import {
  createProPlugin,
  slider,
  autocomplete,
  togglebuttons,
  dropdown,
  datepicker,
  toggle,
  repeater,
  currency,
} from "@formkit/pro";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { createFloatingLabelsPlugin } from "@formkit/addons";
import {
  faEnvelope,
  faSearch,
  faChevronDown,
  faChevronUp,
  faCalendar,
  faChevronRight,
  faChevronLeft,
  faLock,
  faClose,
  faCirclePlus,
  faTrashCan,
  faCheckSquare,
  faCircleNotch,
  faMinus,
  faMinusSquare,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckSquare as faCheckSquareSolid,
  faFireFlameSimple,
  faMinusSquare as faMinusSquareSolid,
  faPlusSquare as faPlusSquareSolid,
  faCheck as faCheckSolid,
} from "@fortawesome/pro-solid-svg-icons";
import "@formkit/addons/css/floatingLabels";

const formKitProPlugin = createProPlugin("fk-51c11b55e9", {
  autocomplete,
  slider,
  togglebuttons,
  toggle,
  dropdown,
  datepicker,
  repeater,
  currency,
});

export default {
  plugins: [createFloatingLabelsPlugin(), formKitProPlugin],
  icons: {
    search: icon(faSearch).html[0],
    select: icon(faChevronDown).html[0],
    selected: icon(faCheckSquareSolid).html[0],
    date: icon(faCalendar).html[0],
    up: icon(faChevronUp).html[0],
    right: icon(faChevronRight).html[0],
    left: icon(faChevronLeft).html[0],
    email: icon(faEnvelope).html[0],
    password: icon(faLock).html[0],
    close: icon(faClose).html[0],
    add: icon(faCirclePlus).html[0],
    trash: icon(faTrashCan).html[0],
    checkboxDecorator: icon(faCheckSquare).html[0],
    check: icon(faCheckSolid).html[0],
    checkSolid: icon(faCheckSolid).html[0],
    flame: icon(faFireFlameSimple).html[0],
    loader: icon(faCircleNotch).html[0],
    minus: icon(faMinus).html[0],
    minusSquare: icon(faMinusSquare).html[0],
    minusSquareSolid: icon(faMinusSquareSolid).html[0],
    plusSquareSolid: icon(faPlusSquareSolid).html[0],
  },
  config: {
    rootClasses,
    // classes: generateClasses({
    //   global: {
    //     message: "motion-safe:animate-duration-150 text-red-600 motion-safe:animate-shake",
    //   },
    //   togglebuttons: {
    //     outer: "!h-9",
    //     input: "aria-pressed:!bg-blue !h-9 !ring-gray-300 !ring-1",
    //   },
    //   autocomplete: {
    //     outer: "!h-9",
    //     input: "!h-9",
    //   },
    //   dropdown: {
    //     selector: "!h-9",
    //   },
    //   button: {
    //     input: "!bg-transparent",
    //   },
    // }),
  },
};
