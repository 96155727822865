import { userFactory } from "@/bapi-client/factories/users";
import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { BAPIWaybillActivityFeedDiff, WaybillActivityFeedDiff, WaybillDetails } from "@/bapi-client/types/waybills";
import type { HandlerParamsWaybill } from "@/bapi-client/types/waybills";

function waybillActivityFeedFactory(data: BAPIWaybillActivityFeedDiff): WaybillActivityFeedDiff {
  const userData = {
    ...data.user,
    companies: [],
  };

  return {
    historyId: data.history_id,
    waybillId: data.waybill_id,
    customerId: data.customer_id,
    userId: data.user_id,
    createdByUserId: data.created_by_user_id,
    overrideField: data.override_field,
    overrideDisplayName: data.override_display_name,
    oldValue: data.old_value,
    newValue: data.new_value,
    createdTs: data.created_ts,
    updatedTs: data.updated_ts,
    operationTs: data.operation_ts,
    operation: data.operation,
    source_table: data.source_table,
    metadata: data.metadata ?? undefined,
    user: userFactory(userData),
  };
}

export async function getWaybillDetailsActivityFeed(...[customerId, waybillId]: HandlerParamsWaybill) {
  const response = await fetchHandler(
    BAPI_COMMANDS.WAYBILL_GET_ACTIVITY_FEED,
    "GET",
    `/waybills/${customerId}/activity_feed/${waybillId}/diff`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: parsed.data.data.map((item) => waybillActivityFeedFactory(item)),
  };
}

export async function getWaybillDetails(
  ...[customerId, waybillId]: HandlerParamsWaybill
): Promise<Result<WaybillDetails>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS,
    "GET",
    `/waybills/${customerId}/detail/${waybillId}`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: parsed.data,
  };
}
