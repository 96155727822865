import { BAPIError } from "@/bapi-client/errors";
import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import {
  ReportListItem,
  ReportBuilderReport,
  ReportBuilderReportData,
  BAPIParamsCreateReport,
  ReportSchedule,
  BAPIParamReportShare,
  BAPIParamUpdateReport,
} from "@/bapi-client/types/report-builder";

export async function getReportBuilderReportList(companyId: string): Promise<Result<ReportListItem[]>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_LIST,
    "GET",
    `/reports/${companyId}/report`,
  );

  if (!fetchResult.success) {
    return fetchResult;
  }

  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function getReportBuilderReport(
  companyId: string,
  reportId: string,
): Promise<Result<ReportBuilderReport>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_DETAILS,
    "GET",
    `/reports/${companyId}/report/${reportId}/get`,
  );
  if (!fetchResult.success) return fetchResult;
  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function getReportDownload(
  companyId: string,
  reportId: string,
  filetype: "csv" | "pdf" | "xlsx",
): Promise<Result<File>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_DOWNLOAD_REPORT,
    "GET",
    `/reports/${companyId}/report/${reportId}/${filetype}`,
  );
  if (!fetchResult.success) return fetchResult;
  const response = fetchResult.data.raw();
  const blob = await response.blob().catch((e) => {
    return { success: false as const, error: new Error(e) };
  });
  if (!(blob instanceof Blob)) return blob;
  const filename = response.headers.get("Content-Disposition")
    ? `${response.headers.get("Content-Disposition")?.split("filename=")[1]?.replaceAll('"', "") ?? "downloaded-file"}`
    : "downloaded-file";

  return { success: true, data: new File([blob], filename, { type: blob.type }) };
}

export async function deleteReport(companyId: string, reportId: string): Promise<Result<void>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_DELETE_REPORT,
    "DELETE",
    `/reports/${companyId}/report/${reportId}`,
  );
  if (!fetchResult.success) return fetchResult;
  return { success: true, data: undefined };
}

export async function getReportData(companyId: string, reportId: string): Promise<Result<ReportBuilderReportData>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_DATA,
    "GET",
    `/reports/${companyId}/report/${reportId}`,
  );
  if (!fetchResult.success) return fetchResult;
  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function createReport(
  companyId: string,
  report: BAPIParamsCreateReport,
): Promise<Result<ReportBuilderReport>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_CREATE_REPORT,
    "POST",
    `/reports/${companyId}/report`,
    { body: report },
  );

  if (!fetchResult.success) return fetchResult;
  const parsed = await fetchResult.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function updateReport(
  companyId: string,
  reportId: string,
  report: BAPIParamUpdateReport,
): Promise<Result<ReportBuilderReport>> {
  const fr = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_UPDATE_REPORT,
    "PATCH",
    `/reports/${companyId}/report/${reportId}`,
    { body: report },
  );

  if (!fr.success) return fr;

  const parsed = await fr.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}

export async function getReportSchedule(companyId: string, reportId: string): Promise<Result<ReportSchedule>> {
  const fetchResult = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_SCHEDULE,
    "GET",
    `/reports/${companyId}/report/${reportId}/schedule`,
  );

  if (!fetchResult.success) return fetchResult;

  const parsed = await fetchResult.data.json();
  if (!parsed.success) {
    return parsed;
  }

  // BAPI theoretically supports multiple but we don't actually as a company.
  // Therefore, we only want the "first" one.
  const [sched] = parsed.data.schedules;
  return sched ? { success: true, data: sched } : { success: false, error: new BAPIError("No schedule found", 404) };
}

export async function shareReport(
  companyId: string,
  reportId: string,
  body: BAPIParamReportShare,
): Promise<Result<boolean>> {
  const fr = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_SHARE_REPORT,
    "POST",
    `/reports/${companyId}/report/${reportId}/send`,
    { body },
  );

  if (!fr.success) return fr;

  return { success: true, data: true };
}

export async function saveReportSchedule(companyId: string, schedule: ReportSchedule): Promise<Result<ReportSchedule>> {
  const { schedule_id, report_id, ...body } = schedule;
  const fr = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_SAVE_REPORT_SCHEDULE,
    "POST",
    `/reports/${companyId}/report/${report_id}/schedule`,
    { body },
  );

  if (!fr.success) return fr;

  const parsed = await fr.data.json();
  if (!parsed.success) {
    return parsed;
  }

  const sched = parsed.data.schedule;
  return { success: true, data: sched };
}

export async function previewReport(
  companyId: string,
  templateId: string,
  body: Pick<BAPIParamsCreateReport, "filters" | "sort_fields">,
): Promise<Result<ReportBuilderReportData>> {
  const fr = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_PREVIEW_REPORT,
    "POST",
    `/reports/${companyId}/template/${templateId}/preview`,
    { body },
  );

  if (!fr.success) {
    return fr;
  }

  const parsed = await fr.data.json();
  if (!parsed.success) return parsed;
  return { success: true, data: parsed.data };
}

export async function deleteReportSchedule(
  companyId: string,
  reportId: string,
  scheduleId: string,
): Promise<Result<void>> {
  const fr = await fetchHandler(
    BAPI_COMMANDS.REPORT_BUILDER_DELETE_REPORT_SCHEDULE,
    "DELETE",
    `/reports/${companyId}/report/${reportId}/schedule/${scheduleId}`,
  );

  if (!fr.success) return fr;

  return { success: true, data: undefined };
}
