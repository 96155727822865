import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import {
  InvoicingPayee,
  InvoicingCompany,
  ExternalInvoiceForm,
  ExternalInvoiceResponse,
} from "@/bapi-client/types/external-invoicing";
import { invoicingPayeeFactory, invoicingCompanyFactory } from "@/bapi-client/factories/external-invoicing";

type CreateCompanyParams = Pick<InvoicingCompany, "name" | "settings">;

export async function getInvoicingPayee(customerId: string): Promise<Result<InvoicingPayee>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_PAYEE,
    "GET",
    `/invoicing/${customerId}/company`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingPayeeFactory(parsed.data),
  };
}

export async function updateInvoicingPayee(
  customerId: string,
  payeeProfileId: string,
  body: InvoicingPayee,
): Promise<Result<InvoicingPayee>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_PAYEE,
    "PATCH",
    `/invoicing/${customerId}/company/${payeeProfileId}`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingPayeeFactory(parsed.data),
  };
}

export async function getInvoicingCompany(customerId: string, payerId: string): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_COMPANY,
    "GET",
    `/invoicing/${customerId}/payer_company/${payerId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function createInvoicingCompany(
  customerId: string,
  body: CreateCompanyParams,
): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_COMPANY,
    "POST",
    `/invoicing/${customerId}/payer_company/init`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function updateInvoicingCompany(
  customerId: string,
  payerId: string,
  body: InvoicingCompany,
): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_COMPANY,
    "PATCH",
    `/invoicing/${customerId}/payer_company/${payerId}`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function createExternalInvoice(
  customerId: string,
  payeeId: string,
  form: ExternalInvoiceForm,
): Promise<Result<ExternalInvoiceResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_INVOICE,
    "POST",
    `/invoicing/${customerId}/payee/${payeeId}/invoices`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return {
    success: true,
    data: json.data,
  };
}
