import {
  internalToolsCustomerFactory,
  internalToolsCustomerListFactory,
} from "@/bapi-client/factories/internal-tools/customers";
import { fetchHandler } from "@/bapi-client/fetch";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import {
  HandlerParamsCreateCustomer,
  HandlerParamsCustomerEquipmentAdd,
  HandlerParamsCustomerEquipmentDelete,
  HandlerParamsDeleteCustomer,
  HandlerParamsUpdateCustomer,
  HandlerResponseCustomerEquipmentAdd,
  HandlerResponseCustomerEquipmentDelete,
  InternalToolsCustomer,
  InternalToolsCustomerListItem,
} from "@/bapi-client/types/internal_tools";
import { type Result } from "../../types";

export async function adminGetCustomer(customerId: string): Promise<Result<InternalToolsCustomer>> {
  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_GET_CUSTOMER, "GET", `/admin/customers/${customerId}`);
  if (!result.success) {
    return result;
  }

  const parsed = await result.data.json();
  if (!parsed.success) {
    return parsed;
  }

  return {
    success: true,
    data: internalToolsCustomerFactory(parsed.data),
  };
}

export async function adminGetCustomers(): Promise<Result<InternalToolsCustomerListItem[]>> {
  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_GET_CUSTOMERS, "GET", "/admin/customers");
  if (!result.success) return result;
  const parsed = await result.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: parsed.data.customers.map(internalToolsCustomerListFactory),
  };
}

export async function adminCreateCustomer(opts: HandlerParamsCreateCustomer): Promise<Result<InternalToolsCustomer>> {
  //@TODO make the fetch handler smarter, it should know what BAPI expects,
  // and expect the correct body type, and it can stringify its own damn JSON.

  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_CREATE_CUSTOMER, "POST", "/admin/customers", {
    body: opts,
  });

  if (!result.success) {
    return result;
  }

  const parsed = await result.data.json();
  if (!parsed.success) {
    return parsed;
  }

  return { success: true, data: internalToolsCustomerFactory(parsed.data) };
}

export async function adminUpdateCustomer(opts: HandlerParamsUpdateCustomer): Promise<Result<InternalToolsCustomer>> {
  const { customerId, ...payload } = opts;
  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_UPDATE_CUSTOMER, "PUT", `/admin/customers/${customerId}`, {
    body: payload,
  });
  if (!result.success) {
    return result;
  }

  const parsed = await result.data.json();
  return parsed.success ? { success: true, data: internalToolsCustomerFactory(parsed.data) } : parsed;
}

export async function adminDeleteCustomer(opts: HandlerParamsDeleteCustomer): Promise<Result<InternalToolsCustomer>> {
  const { customerId } = opts;
  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_DELETE_CUSTOMER, "DELETE", `/admin/customers/${customerId}`);

  if (!result.success) return result;
  const parsed = await result.data.json();
  if (!parsed.success) return parsed;
  return { success: true, data: internalToolsCustomerFactory(parsed.data) };
}

export async function adminAddCustomerEquipment(
  opts: HandlerParamsCustomerEquipmentAdd,
): Promise<Result<HandlerResponseCustomerEquipmentAdd>> {
  const { customerId, equipmentIds } = opts;
  const result = await fetchHandler(
    BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_ADD,
    "POST",
    `/admin/customers/${customerId}/assets_bulk`,
    {
      body: { equipment_ids: equipmentIds },
    },
  );
  if (!result.success) return result;
  const parsed = await result.data.json();
  if (!parsed.success) return parsed;
  const { results, ...rest } = parsed.data;
  return {
    success: true,
    data: {
      results,
      ...internalToolsCustomerFactory(rest),
    },
  };
}

export async function adminDeleteCustomerEquipment(
  opts: HandlerParamsCustomerEquipmentDelete,
): Promise<Result<HandlerResponseCustomerEquipmentDelete>> {
  const { customerId, equipmentIds } = opts;
  const result = await fetchHandler(
    BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_ADD,
    "POST",
    `/admin/customers/${customerId}/assets_bulk_delete`,
    {
      body: { equipment_ids: equipmentIds },
    },
  );
  if (!result.success) return result;
  const parsed = await result.data.json();
  if (!parsed.success) return parsed;
  const { results, ...rest } = parsed.data;
  return {
    success: true,
    data: {
      results,
      ...internalToolsCustomerFactory(rest),
    },
  };
}
