import { fetchHandler } from "@/bapi-client/fetch";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { Permission } from "@/bapi-client/types/internal_tools";
import { Result } from "@/bapi-client/types";

export async function adminListPermissions(): Promise<Result<{ permissions: Permission[] }>> {
  const result = await fetchHandler(BAPI_COMMANDS.ADMIN_LIST_PERMISSIONS, "GET", "/admin/permissions");
  if (!result.success) return result;

  const parsed = await result.data.json();
  if (!parsed.success) return parsed;

  const { permissions } = parsed.data;

  return {
    success: true,
    data: { permissions },
  };
}

export async function adminGetCustomerPermissions(customerId: string): Promise<Result<{ permissions: Permission[] }>> {
  const result = await fetchHandler(
    BAPI_COMMANDS.ADMIN_GET_CUSTOMER_PERMISSIONS,
    "GET",
    `/admin/permissions/${customerId}`,
  );
  if (!result.success) return result;

  const parsed = await result.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data,
  };
}

export async function adminUpdateCustomerPermissions(
  customerId: string,
  permissions: { [permissionId: string]: boolean },
): Promise<Result<{ permissions: Permission[] }>> {
  const result = await fetchHandler(
    BAPI_COMMANDS.ADMIN_UPDATE_CUSTOMER_PERMISSIONS,
    "POST",
    `/admin/permissions/${customerId}`,
    {
      body: { permissions },
    },
  );
  if (!result.success) return result;

  const parsed = await result.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data,
  };
}
