import { ref, Ref } from "vue";

export function useDebounce(time: number) {
  const timeout: Ref<number | null> = ref(null);

  function debounce(callback: () => void) {
    if (timeout.value) {
      window.clearTimeout(timeout.value);
    }
    timeout.value = window.setTimeout(() => {
      callback();
      timeout.value = null;
    }, time);
  }

  return { debounce };
}
