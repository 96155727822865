<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { usePapiClient } from "@/composables/papiClient";
import useTrackerStore from "@/stores/trackers";
import { TenderStatusItem } from "@telegraphio/papi-client";
import { TgPagination, TgTable } from "@/components/common";
import { LayoutBox } from "@/components/layout";
import { useSorted } from "@/composables/sorted";
import { useDebounce } from "@/composables/debounce";
import TenderRequestListItem from "./TenderRequestListItem.vue";
import { snakeToUpperCase } from "@/utils/text";

const props = defineProps<{
  companyId: string;
}>();

const { papiApi } = usePapiClient();
const trackers = useTrackerStore();

const keywordFilter = ref("");
const tenderRequests = ref<TenderStatusItem[]>([]);
const currentPage = ref(1);
const pageSize = ref(25);

const stateMap: Record<string, string> = {
  tender_responded_accepted: "Tender Accepted",
  tender_responded_rejected: "Tender Rejected",
  acknowledged_accepted: "Acknowledgement Accepted",
  acknowledged_rejected: "Acknowledgement Rejected",
};
const columns = [
  { label: "Status", value: "state" },
  "BOL",
  "Control #",
  { label: "Submitted", value: "submittedDate" },
  "Equip. ID",
  "Shipment #",
  { label: "Origin", value: "origin" },
  { label: "Destination", value: "destination" },
  "",
];

const { debounce } = useDebounce(800);

const totalPages = computed(() => Math.ceil(tenderRequests.value.length / pageSize.value));

const filteredRequests = computed(() => {
  const requests = tenderRequests.value.map((request) => {
    const state =
      request.state && stateMap[request.state] ? stateMap[request.state] : snakeToUpperCase(request.state || "");

    return {
      ...request,
      state,
    };
  });

  if (!keywordFilter.value) {
    return requests;
  }

  const keywords = keywordFilter.value.toLowerCase();

  return requests.filter((request) => {
    return request.state.toLowerCase().includes(keywords) || request.equipmentId?.toLowerCase().includes(keywords);
  });
});

const { sorted, sortColumn, sortDirection } = useSorted(filteredRequests, "submittedDate", "DESC");

const paginatedRequests = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return sorted.value.slice(start, end);
});

function handleSort(column: string | number | string[]) {
  sortDirection.value = sortDirection.value === "DESC" ? "ASC" : "DESC";
  sortColumn.value = column as string;

  trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_LIST_SORT, {
    column: column as string,
    direction: sortDirection.value,
  });
}

function handleTrackSearch(searchTerm: string | undefined) {
  debounce(() => {
    if (!searchTerm) {
      return;
    }

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_LIST_SEARCH, {
      search_term: searchTerm,
    });
  });
}

onMounted(async () => {
  try {
    const { tenderStatuses = [] } = await papiApi.bookingListTenderStatuses({
      customerId: props.companyId,
      listTenderStatusesRequest: {},
    });

    tenderRequests.value = tenderStatuses;
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <div>
    <LayoutBox class="mb-6 p-6">
      <FormKit
        v-model="keywordFilter"
        type="text"
        name="filter"
        placeholder="Filter by equipment ID or status"
        :classes="{ wrapper: 'w-72', outer: 'w-72 !flex-grow-0', inner: '!w-72', prefixIcon: '!text-gray-400' }"
        prefix-icon="search"
        @input="handleTrackSearch"
      />
    </LayoutBox>

    <LayoutBox>
      <TgPagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :last-page="totalPages"
        :total="filteredRequests.length"
        with-page-size
        class="p-6"
      />
      <TgTable
        class="pt-3"
        :columns="columns"
        :sort-column="sortColumn"
        :sort-direction="sortDirection"
        @sort="handleSort"
      >
        <TenderRequestListItem v-for="(request, index) in paginatedRequests" :key="index" :tender-request="request" />
      </TgTable>
    </LayoutBox>
  </div>
</template>
